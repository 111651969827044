<template>
  <div
    class="relative"
    :class="[submissionForm?.isAdminEdit ? 'border-8 border-red-600' : '']"
  >
    <jump-to-section
      v-if="submissionFormMode !== 'NEW'"
      class="sticky custom-top z-50"
    />
    <div
      v-if="submissionForm.contractNumber"
      :class="submissionFormMode !== 'NEW' ? 'px-4 pb-3' : ''"
    >
      <h3
        class="block font-semibold text-center"
        :class="{
          'mb-5': !(submissionFormMode === 'EDIT' && submissionForm.submissionStatus === 'Inflight'),
          'text-red-700': ['Rejected', 'Cancelled'].includes(submissionForm.submissionStatus)
        }"
      >
        Brand Assurance Request Form {{ ['EDIT', 'DRAFT'].includes(submissionFormMode) ?
          `- ${submissionForm.submissionId} (${submissionForm.submissionTypeName} - ${submissionForm.currentStageName} - ${reviewName})`
          : '' }}
        {{ submissionFormMode === 'EDIT' && ['Rejected', 'Cancelled'].includes(submissionForm.submissionStatus) ? submissionForm.submissionStatus : '' }}
      </h3>
      <h3
        v-if="submissionForm?.isAdminEdit"
        class="font-bold text-primary-red text-center my-3"
      >
        ADMIN EDIT
      </h3>
      <template v-if="submissionFormMode === 'EDIT' && submissionForm.submissionStatus === 'Inflight'">
        <h3 class="block font-semibold text-center mb-3">
          {{ submissionForm.assignUser ? `Claimed by: ${submissionForm.assignUser}` : '' }}
        </h3>
        <div
          v-if="cancelThisItemFieldState !== INVISIBLE"
          class="flex justify-center mb-4"
        >
          <base-button
            class="flex items-center h-7 py-1"
            variant="btn-primary"
            text="Cancel This Item - Not Proceeding"
            @click="setCancelSubmissionModalVisibility(true)"
          />
        </div>
      </template>
      <form>
        <fieldset
          :disabled="isReadOnlyUser || submissionForm.submissionStatus === 'Completed' ||
            ((submissionForm.assignUser !== userId && submissionFormMode !== 'NEW') && !submissionForm.isAdminEdit)"
        >
          <licensee-details id="licenseeDetails" />
          <submission-details id="submissionDetails" />
          <hasbro-details
            v-if="submissionForm.submissionTypeName === 'Standard' && ['hasbro', 'hasbro inc.'].includes(submissionForm.licenseeName.toLowerCase())"
            id="hasbroDetails"
          />
          <social-review-details
            v-if="submissionForm.submissionTypeName === 'Generic' && $baConstants.includes(submissionForm.materialsSupplied) || $baDigitalVideoConstant === submissionForm.materialsSupplied || $baSocialConstant === submissionForm.materialsSupplied"
            id="socialDetails"
          />
          <span id="safetyDocuments">
            <safety-documents
              v-if="submissionForm.safetyDocIncluded === 'Yes'"
            />
          </span>
          <sample-details
            v-if="submissionForm.physicalSampleIncluded === 'Yes'"
            id="sampleDetails"
          />
          <return-sample-details
            v-if="submissionForm.returnSample === 'Yes'"
            id="returnSampleDetails"
          />
          <span id="characters">
            <characters
              v-if="!['Generic', 'Print on Demand Art'].includes(submissionForm.submissionTypeName)"
            />
          </span>
          <sku-section
            v-if="!['Generic', 'Press Releases, Blogs, Media Alerts, any other PR', 'Print on Demand Art'].includes(submissionForm.submissionTypeName)"
            id="skuSection"
          />
          <span id="attachedDocuments">
            <upload-documents />
          </span>
          <comments id="comments" />
        </fieldset>
        <fieldset>
          <collaborations
            v-if="submissionFormMode === 'EDIT' && sectionPermissions.collaborations"
            id="collaborations"
          />
        </fieldset>
        <fieldset
          :disabled="isReadOnlyUser || submissionForm.submissionStatus === 'Completed' ||
            ((submissionForm.assignUser !== userId && submissionFormMode !== 'NEW') && !submissionForm.isAdminEdit)"
        >
          <submission-routing
            v-if="(submissionFormMode === 'EDIT' && sectionPermissions.submissionRouting) || submissionForm.isAdminEdit"
            id="submissionRouting"
          />
        </fieldset>

        <!-- Action buttons -->
        <div
          v-if="isSubmissionLoading || isAttachmentsLoading || isSafetyDocsLoading"
          class="w-full mt-6 pb-2 px-2 mx-auto text-center"
        >
          <base-svg
            class="h-4 w-4 mr-2 text-primary-red inline-block"
            src="icons/circleSpinner.svg"
            tag="span"
          />
          <span>Loading submission details. Please wait.</span>
        </div>
        <div
          v-else
          class="flex justify-between mt-6"
        >
          <fieldset
            :disabled="__submissionLoadingTracker.isFormSubmitting ||
              isAttachmentsLoading ||
              isSafetyDocsLoading ||
              isReadOnlyUser ||
              submissionForm.submissionStatus === 'Completed' ||
              (submissionForm.assignUser !== userId && submissionFormMode !== 'NEW')"
          >
            <div>
              <base-button
                v-if="(['Draft', 'Inflight'].includes(submissionForm.submissionStatus) || submissionFormMode === 'NEW')"
                class="py-1 mr-6"
                :is-loading="__submissionLoadingTracker.isFormSubmitting"
                variant="btn-primary"
                text="Submit"
                @click.stop.prevent="handleFormSubmit()"
              />
              <base-button
                v-if="submissionFormMode !== 'NEW' && submitAndNextButtonState !== INVISIBLE"
                class="py-1 mr-3"
                :is-loading="__submissionLoadingTracker.isFormSubmitting"
                variant="btn-primary"
                text="Submit & Next"
                type="button"
                @click="handleSubmitAndNext()"
              />
            </div>
          </fieldset>
          <div class="flex">
            <base-button
              v-if="adminEditButtonState !== INVISIBLE && !isReadOnlyUser"
              class="py-1 mr-4"
              :is-loading="__submissionLoadingTracker.isFormSubmitting"
              :disabled="submissionForm.isAdminEdit"
              variant="btn-link"
              text="Admin Edit"
              type="button"
              @click="handleAdminEdit()"
            />
            <base-button
              v-if="isAnnualSamplesAvailalbe && submissionFormMode === 'EDIT' && !isReadOnlyUser"
              class="py-1 mr-4"
              :is-loading="__submissionLoadingTracker.isFormSubmitting"
              variant="btn-link"
              :text=" submissionForm.currentStepName == 'Licensee' ? 'Resubmit' : 'Start Annual Samples'"
              type="button"
              @click="initAnnualSamples"
            />
            <base-button
              v-if="submissionFormMode === 'EDIT' && submissionForm.submissionStatus !== 'Draft'"
              :disable="isPrintingReceipts"
              class="py-1 mr-4"
              :is-loading="__submissionLoadingTracker.isFormSubmitting"
              variant="btn-link"
              text="Download Receipt"
              type="button"
              @click="printReceipts"
            />
            <base-button
              v-if="(submissionFormMode !== 'NEW' && submissionForm.submissionStatus !== 'Draft') && !isReadOnlyUser"
              class="py-1 mr-4"
              :is-loading="isCreatingCopySubmission || __submissionLoadingTracker.isFormSubmitting"
              variant="btn-link"
              text="Create Similar"
              type="button"
              @click="createSimilarSubmission()"
            />
            <base-button
              v-if="saveBtnFieldState !== INVISIBLE && !isReadOnlyUser"
              class="py-1 mr-4"
              :disabled="submissionForm.assignUser !== userId && submissionFormMode !== 'NEW'"
              :is-loading="__submissionLoadingTracker.isFormSubmitting"
              variant="btn-link"
              :text="submissionForm.submissionStatus === 'Completed' ? 'Update Details' : 'Save'"
              type="button"
              @click="saveSubmission()"
            />
            <base-button
              v-if="submissionFormMode === 'EDIT'"
              class="py-1 mr-4"
              :is-loading="__submissionLoadingTracker.isFormSubmitting"
              variant="btn-link"
              text="Close"
              type="button"
              @click="closeSubmission()"
            />
            <base-button
              v-if="(submissionFormMode !== 'EDIT' || submissionForm.submissionStatus === 'Draft') && !isReadOnlyUser"
              class="py-1"
              :is-loading="__submissionLoadingTracker.isFormSubmitting"
              variant="btn-link"
              text="Discard"
              type="button"
              @click="handleDiscardSubmission()"
            />
          </div>
        </div>
      </form>
      <!-- confirm cancel submision modal -->
      <base-modal
        v-model="showCancelSubmissionModal"
        modal-title="Confirm Cancel Submission"
      >
        <template #modalBody>
          <div class="px-2 py-3">
            <div class="font-sm text-center mb-2">
              Are you sure you want to cancel this item? It will not be recoverable and you will have to restart the submission at the concept stage.
            </div>
            <div class="my-2 text-center">
              <base-button
                class="mr-4"
                variant="btn-primary"
                text="Yes"
                :is-loading="cancellingSubmission"
                @click="cancelSubmission()"
              />
              <base-button
                variant="btn-link"
                text="No"
                @click="setCancelSubmissionModalVisibility(false)"
              />
            </div>
          </div>
        </template>
      </base-modal>
      <!-- confirm form submission -->
      <base-modal
        v-model="isConfirmSubmissionModalVisible"
        modal-title="Confirm Submission"
      >
        <template #modalBody>
          <div class="px-2 py-3">
            <div class="font-sm text-center mb-2">
              Are you sure to submit?
            </div>
            <div
              v-if="(submissionFormMode !== 'EDIT' || submissionForm.submissionStatus === 'Draft' || submissionForm.currentStepName == 'Licensee') && $baSocialConstant === submissionForm.materialsSupplied && submissionForm.submissionTypeName === 'Generic'"
              class="font-sm text-center mb-2"
            >
              If Social Media Post, please verify that you have attached a filled out copy template.
            </div>
            <div
              v-if="(submissionFormMode !== 'EDIT' || submissionForm.submissionStatus === 'Draft' || submissionForm.currentStepName == 'Licensee') && submissionForm.materialsSupplied === 'Digital Video Review' && submissionForm.submissionTypeName === 'Generic'"
              class="font-sm text-center mb-2"
            >
              If <b>Digital Video Review</b> submission, please verify you have attached and filled out the Digital Video Review template.
            </div>
            <div class="my-2 text-center">
              <base-button
                class="mr-4"
                :is-loading="__submissionLoadingTracker.isFormSubmitting"
                variant="btn-primary"
                text="Yes"
                @click="submitForm()"
              />
              <base-button
                variant="btn-link"
                text="No"
                @click="setConfirmSubmissionModalVisibility(false)"
              />
            </div>
          </div>
        </template>
      </base-modal>
      <!-- confirm discard submission modal -->
      <base-modal
        v-model="showDiscardSubmissionModal"
        modal-title="Confirm Discard Submission"
      >
        <template #modalBody>
          <div class="px-2 py-3">
            <div class="font-sm text-center mb-2">
              Are you sure to discard this submission?
            </div>
            <div class="my-2 text-center">
              <base-button
                class="mr-4"
                variant="btn-primary"
                text="Yes"
                @click="discardSubmission()"
              />
              <base-button
                variant="btn-link"
                text="No"
                @click="setDiscardSubmissionModalVisibility(false)"
              />
            </div>
          </div>
        </template>
      </base-modal>
      <!-- bottom of page element -->
      <div
        id="bottom"
        class="opacity-0"
      />
    </div>
  </div>
</template>

<script>
import { computed, defineAsyncComponent, onMounted, onUnmounted, ref, watch, reactive } from 'vue';
import { useStore } from 'vuex';
import useSubmissionForm from './submissionForm.js';
import useSubmissionLoadTracker from './submissionLoadTracker.js';
import useSubmissionFormModal from './submissionFormModal.js';
import useUserAuth from '@/hooks/userAuth.js';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { REQUIRED_FIELD } from '@/constants/validationMessages.js';
import { useRouter } from 'vue-router';
import useUploadStatusPopUp from './uploadStatusPopUp';

import {
    ACTIVE_BUTTON, CANCEL_STATUS, INVISIBLE, LICENSEE_STEP, REJECT_STATUS, CREATIVE_REVIEW_STEP, LEGAL_REVIEW_STEP,
    SOCIAL_MEDIA_POST_METARIAL, GENERIC_SUBMISSION_TYPE, EDITABLE, CONCEPT_STAGE, PRE_PRODUCTION_STAGE, DRAFT_STATUS,
    STANDARD_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE, OPA_SUBMISSION_TYPE, CONTRACTUAL_STAGE, ANNUALS_STAGE, REVISED_STAGE,
    SAFETY_STEP, SOCIAL_REVIEW_STEP, DIGITAL_REVIEW_STEP, GAMES_REVIEW_STEP, BAC_FINAL_STEP, BAC_REVIEW_STEP, BAC_SUPERVISOR_STEP, PRINT_ON_DEMAND_SAMPLE_METARIAL, CONTRACT_COORDINATOR, SOCIAL_DIGITAL_VIDEO_REVIEW, PRODUCTION_STAGE, DPA_GOODS_MATERIAL, DPA_AR_MATERIAL, DPA_GAMES_MATERIAL, DPA_STICKER_MATERIAL, DPA_UX_MATERIAL
} from '@/constants/submissions.js';

import {
    SUBMISSION_FORM_ADMIN_EDIT_PERMISSION,
    SUBMISSION_FORM_SUBMISSION_ROUTING_PERMISSION, COLLABORATION_PERMISSION
} from '@/constants/permissions.js';

import {
    BAC_ADMIN_ROLE, BAC_SUPERVISOR_ROLE, BA_ADMIN_ROLE, SKU_ADMIN_ROLE, BAC_REVIEWER_ROLE, CREATIVE_ROLE,
    LEGAL_ROLE, LICENSEE_ROLE, SAFETY_ROLE, SOCIAL_REVIEWER_ROLE, GAMES_REVIEWER_ROLE, DIGITAL_REVIEWER_ROLE, BA_RO_ROLE
} from '@/constants/ba-roles.js';

import {
    ENSURE_SUBMISSION_FORM_IS_VALID, ERROR, SUBMISSION_COPY_FAILED, SUBMISSION_COPY_SUCCESS, SUBMISSION_CREATE_FAILED, SUBMISSION_DISCARD_SUCCESS,
    SUBMISSION_SAVE_FAILED, SUBMISSION_SAVE_SUCCESS, SUBMISSION_UPDATE_FAILED, SUBMISSION_UPDATE_SUCCESS, SUCCESS, WARNING
} from '@/constants/alerts.js';

export default {
    name: 'BrandAssuranceSubmissionForm',

    components: {
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        LicenseeDetails: defineAsyncComponent(() => import('./LicenseeDetails.vue')),
        SubmissionDetails: defineAsyncComponent(() => import('./SubmissionDetails.vue')),
        HasbroDetails: defineAsyncComponent(() => import('./HasbroDetails.vue')),
        SocialReviewDetails: defineAsyncComponent(() => import('./SocialReviewDetails.vue')),
        Characters: defineAsyncComponent(() => import('./Characters.vue')),
        Comments: defineAsyncComponent(() => import('./Comments.vue')),
        ReturnSampleDetails: defineAsyncComponent(() => import('./ReturnSampleDetails.vue')),
        SafetyDocuments: defineAsyncComponent(() => import('./SafetyDocuments.vue')),
        SampleDetails: defineAsyncComponent(() => import('./SampleDetails.vue')),
        SkuSection: defineAsyncComponent(() => import('./SkuSection.vue')),
        SubmissionRouting: defineAsyncComponent(() => import('./SubmissionRouting.vue')),
        UploadDocuments: defineAsyncComponent(() => import('./UploadDocuments.vue')),
        JumpToSection: defineAsyncComponent(() => import('./JumpToSection.vue')),
        Collaborations: defineAsyncComponent(() => import('./Collaborations.vue'))
    },



    emits: [
        'submissionCancelled',
        'discardSubmission',
        'closeSubmission',
        'submitAndNext'
    ],

    setup (props, { emit }) {
        const store = useStore();
        const { gridSubmissionsList, loadedSubmissions } = useSubmissionFormModal();
        const {
            submissionForm, resetSubmissionForm, submissionFormValidations, submissionFormMode, getSubmissionFormDataAsExpectedByServer,
            resetSubmissionFormValidations, isSubmissionFormValid, updateSubmissionForm
        } = useSubmissionForm();

        const { updateSubmissionLoadingTracker, submissionLoadingTracker } = useSubmissionLoadTracker();
        const { userPermissions, userRoles } = useUserAuth();
        const { notificationsStack } = useToastNotifications();
        const { fetchUploadFiles } = useUploadStatusPopUp();
        const userId = computed(() => store.getters['auth/getUserId']);
        const proxyUser = computed(() => store.getters['auth/getUserProxyInfo']);
        const isReadOnlyUser = computed(() => {
            const isReadOnly = store.getters['auth/isReadOnlyUser'];
            return (isReadOnly || proxyUser.value);
        });
        const currentUser = computed(() => store.getters['auth/getUserInfo']);


        // eslint-disable-next-line vue/return-in-computed-property
        const reviewName = computed(() => {
            if (submissionForm.materialsSupplied === SOCIAL_DIGITAL_VIDEO_REVIEW && submissionForm.currentStepName === SOCIAL_REVIEW_STEP) {
                return SOCIAL_DIGITAL_VIDEO_REVIEW;
            } else if (userRoles.value.length <= 2 && ([BA_RO_ROLE, LICENSEE_ROLE].includes(userRoles.value[0]) || [BA_RO_ROLE, LICENSEE_ROLE].includes(userRoles.value[1]))) {
                if (submissionForm.currentStepName === LEGAL_REVIEW_STEP) {
                    return CONTRACT_COORDINATOR;
                } else {
                    return submissionForm.currentStepName;
                }
            } else {
                return submissionForm.currentStepName;
            }
        });

        // submission form button validations
        const submitButtonState = computed(() => [CANCEL_STATUS, REJECT_STATUS].includes(submissionForm.statusName) ? INVISIBLE : ACTIVE_BUTTON);
        const submitAndNextButtonState = computed(() => {
            const lastSubmission = gridSubmissionsList.value.data[gridSubmissionsList.value.data.length - 1];
            if ((!submissionForm.currentStepName || LICENSEE_STEP === submissionForm.currentStepName) || submissionForm.submissionId === lastSubmission.submissionId) {
                return INVISIBLE;
            } else {
                return ACTIVE_BUTTON;
            }
        });
        const adminEditButtonState = computed(() => {
            if (
                userPermissions.value.includes(SUBMISSION_FORM_ADMIN_EDIT_PERMISSION) &&
              [BAC_SUPERVISOR_ROLE, BAC_ADMIN_ROLE, BA_ADMIN_ROLE, SKU_ADMIN_ROLE].some(role => userRoles.value.includes(role)) &&
              ['Inflight', 'Completed', 'AdminEditEnabled'].includes(submissionForm.submissionStatus)
            ) {
                return ACTIVE_BUTTON;
            } else {
                return INVISIBLE;
            }
        });

        // creating copy submission logic
        const isCreatingCopySubmission = ref(false);
        const similarSubmission = computed(() => store.getters['baSubmissions/getCopySubmission']);

        const { updateSubmissionsTabList } = useSubmissionFormModal();
        const { openSubmissionDetails } = useSubmissionFormModal();

        const createSimilarSubmission = async () => {
            try {
                if (isCreatingCopySubmission.value) {
                    return;
                }
                isCreatingCopySubmission.value = true;
                await store.dispatch('baSubmissions/createCopyOfSubmission', {
                    bodyPayload: {
                        submissionId: submissionForm.submissionId
                    }
                });
                similarSubmission.value.assignUser = userId.value;
                similarSubmission.value.contactName = currentUser.value.name; // `${fetchedLicenseeDetails.value.contactFirstName} ${fetchedLicenseeDetails.value.contactLastName}`,
                // similarSubmission.value.contactPhone = fetchedLicenseeDetails.value.contactPhoneNumber;
                similarSubmission.value.contactEmail = currentUser.value.email; // fetchedLicenseeDetails.value.contactEmailAddres,

                await store.dispatch('baSubmissions/fetchSubmissionDetails', {
                    submissionId: similarSubmission.value.submissionId,
                    assignUser: userId?.value,
                    submissionStatus: similarSubmission.value.submissionStatus ? similarSubmission.value.submissionStatus : '',
                    currentStepName: similarSubmission.value.currentStepName ? similarSubmission.value.currentStepName : ''
                });

                const getAllSteps = computed(() => store.getters['baSubmissions/getSubmissionSteps']);
                submissionStages.value.forEach(stage => {
                    if (stage.stageKey === similarSubmission.value.currentStage) similarSubmission.value.currentStageName = stage.stageName;
                });

                getAllSteps.value.forEach(step => {
                    if (step.stepKey === similarSubmission.value.currentStep) similarSubmission.value.currentStepName = step.stepName;
                });

                similarSubmission.value.socPostedEng = null;
                similarSubmission.value.socPostedPhoto = null;
                similarSubmission.value.socReviewInfluencers = null;
                similarSubmission.value.social = [];
                submissionForm.social = [];

                submissionForm.assets = [];
                // ..copy && open new submission in new tab.. //
                updateSubmissionsTabList([{
                    submissionId: similarSubmission.value.submissionId,
                    licenseeName: similarSubmission.value.licenseeName,
                    assignUser: userId.value,
                    submissionStatus: DRAFT_STATUS, // while creating similar submission status will always be DRAFT //
                    currentStepName: similarSubmission.value.currentStepName ? similarSubmission.value.currentStepName : ''
                }]);
                /* ...clearing skus and comments while creating similar submission and previous stage info... */
                similarSubmission.value.skus = [];
                similarSubmission.value.comments = [];
                similarSubmission.value.previousStage = null;
                similarSubmission.value.previousStageName = null;
                await openSubmissionDetails({
                    ...similarSubmission.value
                });
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: `${SUBMISSION_COPY_SUCCESS}. The new submission Id is ${similarSubmission.value.submissionId}`
                });
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: SUBMISSION_COPY_FAILED
                });
            } finally {
                isCreatingCopySubmission.value = false;
            }
        };

        // submission form logic
        const __submissionLoadingTracker = reactive({
            isFormSubmitting: false
        });

        const handleFormSubmit = () => {
            formAction.value = 'submit';
            if (submissionForm.currentStepName === LICENSEE_STEP) {
                submissionFormValidations.currentAction = '';
                if (submissionForm.submissionStatus === 'Draft') {
                    submissionFormValidations.collaborations = '';
                }
            }
            if ([GENERIC_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) submissionFormValidations.styleGuideName = '';
            if (['No', 'N'].includes(submissionForm.physicalSampleIncluded)) {
                submissionFormValidations.trackingNumber = '';
                submissionFormValidations.deliveryCompany = '';
                submissionFormValidations.currentSampleCount = '';
            }
            if (isSubmissionFormValid()) {
                setConfirmSubmissionModalVisibility(true);
            } else {
                // scroll to first element with error..
                const scrollTarget = document.querySelector('[data-name="errorCircle.svg"]');
                if (scrollTarget) {
                    scrollTarget.scrollIntoView({ block: 'center' });
                }
                notificationsStack.value.push({
                    type: WARNING,
                    message: ENSURE_SUBMISSION_FORM_IS_VALID
                });
            }
        };

        const isSubmitAndNextSelected = ref(false);
        const handleSubmitAndNext = () => {
            formAction.value = 'submit';
            if (submissionForm.currentStepName === LICENSEE_STEP) submissionFormValidations.currentAction = '';
            if ([GENERIC_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) submissionFormValidations.styleGuideName = '';
            if (isSubmissionFormValid()) {
                isSubmitAndNextSelected.value = true;
                setConfirmSubmissionModalVisibility(true);
            } else {
                // scroll to first element with error..
                const scrollTarget = document.querySelector('[data-name="errorCircle.svg"]');
                if (scrollTarget) {
                    scrollTarget.scrollIntoView({ block: 'center' });
                }
                notificationsStack.value.push({
                    type: WARNING,
                    message: ENSURE_SUBMISSION_FORM_IS_VALID
                });
            }
        };
        const router = useRouter();
        const formAction = ref('submit');
        const submitForm = async () => {
            const __isSubmitting = formAction.value === 'submit';
            const __isSaving = formAction.value === 'save';

            try {
                if (__submissionLoadingTracker.isFormSubmitting) {
                    return;
                }
                __submissionLoadingTracker.isFormSubmitting = true;
                setConfirmSubmissionModalVisibility(false);

                // Fix the Draft submission status upon submit
                if (__isSubmitting && submissionForm.submissionStatus === 'Draft') {
                    submissionForm.submissionStatus = 'Inflight';
                }

                if (__isSaving && submissionForm.isAdminEdit && submissionForm.submissionStatus === 'AdminEditEnabled') {
                    submissionForm.submissionStatus = 'Completed';
                }
                /* NA handled while sending the call */
                if (submissionForm.legalNextStage === '--NA--') submissionForm.legalNextStage = null;
                if (submissionForm.legalNextStageName === '--NA--') submissionForm.legalNextStageName = null;
                if (submissionForm.creativeNextStage === '--NA--') submissionForm.creativeNextStage = null;
                if (submissionForm.creativeNextStageName === '--NA--') submissionForm.creativeNextStageName = null;

                // Create the payload as expected by the backend
                const payload = getSubmissionFormDataAsExpectedByServer(__isSubmitting);

                // * * * * * * * *

                // Check the User Role for the Submission Step
                if (submissionForm.currentStepName) {
                    let stepToRole;
                    if (submissionForm.currentStepName === BAC_FINAL_STEP) stepToRole = BAC_ADMIN_ROLE;
                    else if (submissionForm.currentStepName === LICENSEE_STEP) stepToRole = LICENSEE_ROLE;
                    else if (submissionForm.currentStepName === BAC_REVIEW_STEP) stepToRole = BAC_REVIEWER_ROLE;
                    else if (submissionForm.currentStepName === LEGAL_REVIEW_STEP) stepToRole = LEGAL_ROLE;
                    else if (submissionForm.currentStepName === CREATIVE_REVIEW_STEP) stepToRole = CREATIVE_ROLE;
                    else if (submissionForm.currentStepName === SAFETY_STEP) stepToRole = SAFETY_ROLE;
                    else if (submissionForm.currentStepName === SOCIAL_REVIEW_STEP) stepToRole = SOCIAL_REVIEWER_ROLE;
                    else if (submissionForm.currentStepName === GAMES_REVIEW_STEP) stepToRole = GAMES_REVIEWER_ROLE;
                    else if (submissionForm.currentStepName === DIGITAL_REVIEW_STEP) stepToRole = DIGITAL_REVIEWER_ROLE;
                    else if (submissionForm.currentStepName === BAC_SUPERVISOR_STEP) stepToRole = BAC_SUPERVISOR_ROLE;

                    if (!(userRoles.value || []).includes(stepToRole)) {
                        return notificationsStack.value.push({
                            type: ERROR,
                            message: 'User not allowed to make updates to this submission. Please close this form and try again.'
                        });
                    }
                }

                // Assign to the user, if they have the BAC Reviwer role and this is a new submisions
                // .. Using the assignToUser wont work for Draft ... since there are no Tasks created for Draft
                // .. handling that "assignment" in the search API instead
                if ((userRoles.value || []).includes(BAC_REVIEWER_ROLE) && (!payload.currentStep || payload.currentStep === 1 || payload.currentStep === 190)) {
                    payload.assignToUser = userId.value;
                }

                if (__isSubmitting && submissionForm.currentStageName !== CONCEPT_STAGE && submissionForm.currentStepName === LICENSEE_STEP) {
                    if (submissionForm.contractStatus === 'DRAFT') {
                        notificationsStack.value.push({ type: ERROR, message: 'The contract on this submission is in DRAFT status. You will be allowed to submit for ' + submissionForm.currentStageName + ' stage only on a booked contract. Please contact your deal originator.' });
                        return;
                    }
                }

                // For SUBMIT .. Check the asset content
                if (__isSubmitting && process.env.VUE_APP_TITLE !== 'ASGARD (Dev)') {
                    // Check for submission attachment
                    if (!payload.assets || !payload.assets.some(a => a.assetType === 'SUBMISSION' || a.assetType === null) || submissionForm.attachmentsToBeUpdated?.length > 0) { // adding null check because of migrated submissions.
                        notificationsStack.value.push({ type: ERROR, message: 'Upload at least one (1) Attachment for the submission.' });
                        return;
                    }

                    // Check for safety attachment .. only if the flag is turned on
                    if (((payload.safetyDocIncluded === 'Y' && payload.currentStepName === LICENSEE_STEP) &&
                    (!payload.assets || !payload.assets.some(a => a.assetType === 'SAFETY'))) || submissionForm.attachmentsToBeUpdated?.length > 0
                    ) {
                        notificationsStack.value.push({ type: ERROR, message: 'Upload at least one (1) Safety Document for the submission.' });
                        return;
                    }
                }

                // Remove the assets from the payload .. asset mgmt is handled by other services
                delete payload.assets;

                // Check if there is at least ONE new comment .. otherwise warn the user and throw and exception
                if (__isSubmitting) {
                    const someNewComment = payload.comments.some(c => !c.noteKey);
                    if (!someNewComment) {
                        notificationsStack.value.push({
                            type: WARNING,
                            message: 'Please enter a comment to continue.'
                        });
                        notificationsStack.value.push({
                            type: ERROR,
                            message: 'The submission must have a new comment.'
                        });

                        return;
                    }
                }

                // ALWAYS 'claim' the task before submitting
                if (submissionForm.submissionId && submissionForm.taskId && submissionForm.currentStepName) {
                    try {
                        const bodyPayload = {
                            submissions: [{
                                submissionId: submissionForm.submissionId,
                                taskId: submissionForm.taskId,
                                currentStepName: submissionForm.currentStepName
                            }],
                            action: 'claim'
                        };
                        await store.dispatch('baSubmissions/submissionTasks', { bodyPayload });
                    } catch (claimErr) {
                        return notificationsStack.value.push({
                            type: ERROR,
                            message: 'The submission details are not valid. Please close this form and try again.'
                        });
                    }
                }

                // Set currentAction to "Submitted" .. for Licensee Step ONLY .. to avoid cancelling the submission
                // ... This is just to enforce the correct status on submit
                // ... The licensee should be prevented to get to the submit button using other checks in the UI

                if (submissionFormMode.value !== 'NEW') {
                    if (__isSubmitting && payload.currentStepName === LICENSEE_STEP && __submissionStatusList.value && __submissionStatusList.value.length) {
                        const tmpStatus = (__submissionStatusList.value || []).find(sl => sl.submissionType === payload.submissionType && sl.statusName === 'Submitted');
                        if (!tmpStatus) {
                            return notificationsStack.value.push({
                                type: ERROR,
                                message: 'The status values are missing. Please refresh and try again.'
                            });
                        }
                        payload.currentAction = tmpStatus.statusKey;
                        payload.currentActionName = tmpStatus.statusName;
                    }
                }

                // * * * * * * * *

                // CALL THE SUBMISSION UPDATE SERVICE
                await store.dispatch('baSubmissions/updateSubmission', {
                    bodyPayload: { action: formAction.value, payload }
                });

                // Show the message
                let message;
                if (__isSubmitting) {
                    message = submissionFormMode.value === 'NEW'
                        ? `Successfully submitted with Submission Id: ${submissionForm.submissionId}.` : SUBMISSION_UPDATE_SUCCESS;
                } else {
                    message = SUBMISSION_SAVE_SUCCESS;
                    await fetchUploadFiles();
                }
                notificationsStack.value.push({ type: SUCCESS, message });

                await store.dispatch('baSubmissions/removeOpenedSubmission', { submissionId: submissionForm.submissionId });
                if (__isSaving && submissionFormMode.value === 'NEW') {
                    localStorage.setItem('comingFromNewSubmissionForm', true);
                    router.push({ name: 'BADrafts' });
                }

                getSubmissionFormDataAsExpectedByServer(__isSubmitting); // setting data as expected by server on submit and save both.

                if (isSubmitAndNextSelected.value) {
                    // Go to next submission
                    isSubmitAndNextSelected.value = false;
                    emit('submitAndNext', submissionForm.submissionId);
                } else {
                    // Close Submission
                    if (!__isSaving) emit('submissionCancelled', submissionForm.submissionId);
                }
            } catch (err) {
                let updateMessage = err.response?.data?.message;
                if ([BAC_FINAL_STEP, BAC_SUPERVISOR_STEP].includes(submissionForm.currentStepName) && updateMessage.includes('Missing')) {
                    const reviewer = updateMessage?.split(' ');
                    updateMessage = updateMessage + '. Please route back to ' + reviewer[1] + ' reviewer.';
                } else {
                    updateMessage = SUBMISSION_UPDATE_FAILED;
                }
                let message;
                if (__isSubmitting) {
                    message = submissionFormMode.value === 'NEW' ? SUBMISSION_CREATE_FAILED : updateMessage;
                } else {
                    message = SUBMISSION_SAVE_FAILED;
                }
                notificationsStack.value.push({ type: ERROR, message });
            } finally {
                __submissionLoadingTracker.isFormSubmitting = false;
            }
        };
        const handleAdminEdit = () => {
            updateSubmissionForm({
                isAdminEdit: !submissionForm.isAdminEdit,
                assignUser: userId.value,
                submissionStatus: submissionForm.submissionStatus === 'Completed' && !submissionForm.isAdminEdit ? 'AdminEditEnabled' : 'Inflight'
            });
        };
        const saveSubmission = () => {
            formAction.value = 'save';
            checkingEmptyStringStageValues();
            submissionForm.comments = [];
            submitForm();
        };

        const checkingEmptyStringStageValues = () => {
            // these value should be null not empty while saving the submission
            if (submissionForm.creativeStatusName === '' || submissionForm.creativeStatus === '') {
                submissionForm.creativeStatusName = null;
                submissionForm.creativeStatus = null;
            }

            if (submissionForm.legalStatusName === '' || submissionForm.legalStatus === '') {
                submissionForm.legalStatusName = null;
                submissionForm.legalStatus = null;
            }

            if (submissionForm.currentActionName === '' || submissionForm.currentAction === '') {
                submissionForm.currentAction = null;
                submissionForm.currentActionName = null;
            }

            if (submissionForm.legalNextStage === '' || submissionForm.legalNextStageName === '') {
                submissionForm.legalNextStageName = null;
                submissionForm.legalNextStage = null;
            }

            if (submissionForm.currentStage === '' || submissionForm.currentStageName === '') {
                submissionForm.currentStageName = null;
                submissionForm.currentStage = null;
            }

            if (submissionForm.creativeNextStage === '' || submissionForm.creativeNextStageName === '') {
                submissionForm.creativeNextStageName = null;
                submissionForm.creativeNextStage = null;
            }
        };

        const discardSubmission = async () => {
            const payload = {
                submissionId: submissionForm.submissionId,
                submissionSkuKeys: submissionForm.skus.map(s => s.submissionSkuKey).join(',')
            };

            try {
                await store.dispatch('baSubmissions/deleteSubmission', payload);
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: SUBMISSION_DISCARD_SUCCESS
                });
            } catch (e) {
                notificationsStack.value.push({
                    type: ERROR,
                    message: 'Problem in removing the submission. ' + payload.submissionId
                });
            } finally {
                setDiscardSubmissionModalVisibility(false);
            }
            emit('discardSubmission');
            emit('submissionCancelled', payload.submissionId);
        };
        const closeSubmission = () => {
            emit('closeSubmission');
        };

        const saveBtnFieldState = computed(() => {
            if (['Rejected', 'Cancelled'].includes(submissionForm.submissionStatus)) {
                return INVISIBLE;
            } else {
                return EDITABLE;
            }
        });

        const submissionStages = computed(() => store.getters['baSubmissions/getSubmissionStages']);

        const cancelThisItemFieldState = computed(() => {
            const intermediateStageList = submissionStages?.value ? submissionStages.value.filter(stage => stage.isOptionalStage === 'Y') : [];
            if (submissionForm.assignUser === userId.value && !proxyUser.value) {
                if ((submissionForm.currentStageName === PRODUCTION_STAGE && submissionForm?.isAdminEdit) || (
                    [CONCEPT_STAGE, PRE_PRODUCTION_STAGE, REVISED_STAGE].includes(submissionForm.currentStageName) ||
                  intermediateStageList.map(a => a.stageName).includes(submissionForm.currentStageName) ||
                  (CONTRACTUAL_STAGE === submissionForm.currentStageName && OPA_SUBMISSION_TYPE === submissionForm.submissionTypeName)
                )) {
                    return EDITABLE;
                } else {
                    return INVISIBLE;
                }
            } else {
                return INVISIBLE;
            }
        });

        // cancel submission modal logic
        const showCancelSubmissionModal = ref(false);
        const setCancelSubmissionModalVisibility = (visibility) => {
            showCancelSubmissionModal.value = visibility;
        };

        const __submissionStatusList = computed(() => store.getters['baSubmissions/getSubmissionStatusList'] || []);
        const cancellingSubmission = ref(false);
        const cancelSubmission = async () => {
            try {
                if (cancellingSubmission.value) {
                    return;
                }
                cancellingSubmission.value = true;

                let payload;

                const tmpSubmissionIdx = loadedSubmissions.value.findIndex(ls => ls.submissionId === submissionForm.submissionId);
                if (tmpSubmissionIdx >= 0) {
                    const loadedSub = { ...loadedSubmissions.value[tmpSubmissionIdx] };
                    if (loadedSub.characters.length > 0) {
                        loadedSub.characters.forEach(tmpChar => {
                        tmpChar?.isActorLikenessPackaging === 'Y' ? tmpChar.isActorLikenessPackaging = 'Y' : tmpChar.isActorLikenessPackaging = null;
                        tmpChar?.isActorLikenessProduct === 'Y' ? tmpChar.isActorLikenessProduct = 'Y' : tmpChar.isActorLikenessProduct = null;
                        tmpChar?.isActorLikenessVoice === 'Y' ? tmpChar.isActorLikenessVoice = 'Y' : tmpChar.isActorLikenessVoice = null;
                        });
                    }
                    if (loadedSub.skus.length > 0) {
                        loadedSub.skus.forEach(sku => {
                            sku.characters.forEach(skuChar => {
                              skuChar?.isActorLikenessPackaging === 'Y' ? skuChar.isActorLikenessPackaging = 'Y' : skuChar.isActorLikenessPackaging = null;
                              skuChar?.isActorLikenessProduct === 'Y' ? skuChar.isActorLikenessProduct = 'Y' : skuChar.isActorLikenessProduct = null;
                              skuChar?.isActorLikenessVoice === 'Y' ? skuChar.isActorLikenessVoice = 'Y' : skuChar.isActorLikenessVoice = null;
                            });
                        });
                    }
                    const tmpStatus = (__submissionStatusList.value || []).find(s => s.statusName === 'Cancel');
                    if (tmpStatus) {
                        loadedSub.currentAction = tmpStatus.statusKey;
                        loadedSub.currentActionName = tmpStatus.statusName;
                        loadedSubmissions.value[tmpSubmissionIdx] = loadedSub;
                        payload = loadedSub;
                    }
                }

                if (!payload) {
                    notificationsStack.value.push({
                        type: ERROR,
                        message: 'Submission details not provided. Please try again.'
                    });

                    return;
                }

                await store.dispatch('baSubmissions/removeOpenedSubmission', { submissionId: submissionForm.submissionId });

                await store.dispatch('baSubmissions/updateSubmission', { bodyPayload: { action: 'submit', payload } });

                emit('submissionCancelled', submissionForm.submissionId);
                setCancelSubmissionModalVisibility(false);
            } catch (err) {
                console.error(err);
            } finally {
                cancellingSubmission.value = false;
            }
        };

        // discard submission modal logic
        const showDiscardSubmissionModal = ref(false);
        const setDiscardSubmissionModalVisibility = (visibility) => {
            showDiscardSubmissionModal.value = visibility;
        };
        const handleDiscardSubmission = () => {
            setDiscardSubmissionModalVisibility(true);
        };

        // confirm form submission modal logic
        const isConfirmSubmissionModalVisible = ref(false);
        const setConfirmSubmissionModalVisibility = (visibility) => {
            isConfirmSubmissionModalVisible.value = visibility;
        };

        const sectionPermissions = ref({});
        onMounted(async () => {
            await store.dispatch('baSubmissions/fetchSubmissionStages', { params: {} });
            await store.dispatch('baSubmissions/fetchSubmissionSteps', { params: {} });
            await store.dispatch('baSubmissions/fetchSubmissionStatusList', { params: {} });

            if (!userPermissions || !userPermissions.value || !userPermissions.value.length) {
                // logout the user permission are not set
                await store.dispatch('auth/logoutUser');
                // router.push({ name: 'Login' });
                return;
            }
            sectionPermissions.value.collaborations = userPermissions.value.includes(COLLABORATION_PERMISSION);
            sectionPermissions.value.submissionRouting = userPermissions.value.includes(SUBMISSION_FORM_SUBMISSION_ROUTING_PERMISSION);
        });

        onUnmounted(() => {
            resetSubmissionForm();
            resetSubmissionFormValidations();
        });

        watch(
            __submissionLoadingTracker,
            () => {
                updateSubmissionLoadingTracker({ ...__submissionLoadingTracker });
            },
            { deep: true, immediate: true }
        );

        const isAttachmentsLoading = ref(false);
        const isSafetyDocsLoading = ref(false);
        const isSubmissionLoading = ref(false);

        watch(
            submissionLoadingTracker,
            () => {
                if (submissionLoadingTracker && submissionLoadingTracker.isLoadingAttachments) isAttachmentsLoading.value = true;
                else isAttachmentsLoading.value = false;

                if (submissionLoadingTracker && submissionLoadingTracker.isLoadingSafetyDocs) isSafetyDocsLoading.value = true;
                else isSafetyDocsLoading.value = false;

                if (
                    submissionLoadingTracker &&
                (
                    submissionLoadingTracker.isOpeningSubmission ||
                  submissionLoadingTracker.loadingStyleGuides ||
                  submissionLoadingTracker.loadingSGCharacters ||
                  submissionLoadingTracker.collabDetailsLoading
                )
                ) {
                    isSubmissionLoading.value = true;
                } else {
                    isSubmissionLoading.value = false;
                }
            }
        );

        const isPrintingReceipts = ref(false);
        const printReceipts = async () => {
            try {
                if (isPrintingReceipts.value) return;
                isPrintingReceipts.value = true;
                const promises = await store.dispatch('baSubmissions/downloadSubmissionReceipt', {
                    submissionIds: [submissionForm.submissionId]
                });
                const resp = await Promise.all(promises);
                resp.map(r => {
                    if (!r.data || !r.data.data || r.data.message !== 'Receipt downloaded successfully') {
                        notificationsStack.value.push({
                            type: WARNING,
                            message: `Invalid response for submission receipt [ ${r.config.url.split('/')[1]} ]`
                        });
                    } else {
                        const windowOpened = window.open(r.data.data, '_blank');
                        if (!windowOpened) {
                            notificationsStack.value.push({
                                type: ERROR,
                                message: 'A popup blocker may be preventing the application. Please add this site to your exception list in order to upload/ download.'
                            });
                        }
                    }
                });
            } catch (e) {
                console.error(e);
                notificationsStack.value.push({
                    type: ERROR,
                    message: 'Error while downloading receipt'
                });
            } finally {
                isPrintingReceipts.value = false;
            }
        };

        const isAnnualSamplesAvailalbe = computed(() => {
            const isValidSubmissionType = [STANDARD_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName);

            let isValidSubmissionStage = false;
            if (submissionForm.submissionTypeName === STANDARD_SUBMISSION_TYPE) {
                isValidSubmissionStage = [CONTRACTUAL_STAGE, ANNUALS_STAGE].includes(submissionForm.currentStageName);
            } else if (submissionForm.submissionTypeName === PRINT_ON_DEMAND_SUBMISSION_TYPE) {
                isValidSubmissionStage = [CONCEPT_STAGE].includes(submissionForm.currentStageName);
            }

            const isValidStatus = submissionForm.submissionStatus === 'Completed';
            const isValidContract = !['TERMINATED, EXPIRED']?.includes(submissionForm.contractStatus);

            // let isValidSg = false;
            // if (submissionForm.submissionTypeName === STANDARD_SUBMISSION_TYPE) {
            //     isValidSg = !submissionForm.styleGuideName?.toLowerCase()?.includes('movie') && !submissionForm.styleGuideName?.toLowerCase()?.includes('theatrical');
            // } else {
            //     isValidSg = true;
            // }

            return isValidSubmissionType && isValidSubmissionStage && isValidStatus &&
             isValidContract;
            //  && isValidSg;
        });

        const initAnnualSamples = async () => {
            try {
                if (__submissionLoadingTracker.isFormSubmitting) return;
                __submissionLoadingTracker.isFormSubmitting = true;
                await store.dispatch('baSubmissions/initAnnualSamples', { submissionId: submissionForm.submissionId });

                if (submissionForm.submissionTypeName === PRINT_ON_DEMAND_SUBMISSION_TYPE) {
                    notificationsStack.value.push({ type: SUCCESS, message: `Submission is reopened successfully and sent to Licensee. ${submissionForm.submissionId}` });
                } else {
                    notificationsStack.value.push({ type: SUCCESS, message: `Annual Samples initiated for ${submissionForm.submissionId}` });
                }

                emit('submissionCancelled', submissionForm.submissionId);
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({ type: ERROR, message: 'Error while initiating Annual Samples.' });
            } finally {
                __submissionLoadingTracker.isFormSubmitting = false;
            }
        };

        watch(
            () => submissionForm.materialsSupplied,
            () => {
                if (['DRAFT', 'NEW'].includes(submissionFormMode.value) || submissionForm.submissionStatus === 'Draft') {
                    if (submissionForm.materialsSupplied === SOCIAL_MEDIA_POST_METARIAL && submissionForm.submissionTypeName === GENERIC_SUBMISSION_TYPE) {
                        notificationsStack.value.push({
                            type: WARNING,
                            message: 'For Social Media Post questions or inquiries, please refer to the "How to" user guide or send an email to socialreview@marvel.com.',
                            duration: 10000
                        });
                    }

                    if (submissionForm.submissionTypeName === STANDARD_SUBMISSION_TYPE && (submissionForm.submissionStatus === '' || submissionForm.submissionStatus === 'Draft')) {
                        if (submissionForm.materialsSupplied === PRINT_ON_DEMAND_SAMPLE_METARIAL) {
                            submissionForm.currentStage = 49;
                            submissionForm.currentStageName = CONTRACTUAL_STAGE;
                        } else {
                            submissionForm.currentStage = 1;
                            submissionForm.submissionType = 1;
                            submissionForm.currentStageName = CONCEPT_STAGE;
                        }
                    }
                }
            });

        watch(
            () => submissionForm.currentStage,
            () => {
                if (!submissionForm.currentStage) submissionFormValidations.currentStage = REQUIRED_FIELD;
                else submissionFormValidations.currentStage = '';

                if (submissionForm.submissionTypeName === STANDARD_SUBMISSION_TYPE && submissionForm.currentStage === 2 && !submissionForm.onShelfDate) {
                    submissionFormValidations.onShelfDate = REQUIRED_FIELD;
                } else {
                    submissionFormValidations.onShelfDate = '';
                }
            }
        );

        return {
            userId,
            currentUser,
            // submission form button validations
            submitButtonState,
            reviewName,
            submitAndNextButtonState,
            adminEditButtonState,
            INVISIBLE,
            // submission form
            submissionForm,
            submissionFormMode,
            handleFormSubmit,
            handleSubmitAndNext,
            submitForm,
            isSubmissionFormValid,
            isCreatingCopySubmission,
            createSimilarSubmission,
            handleAdminEdit,
            saveSubmission,
            discardSubmission,
            closeSubmission,
            // cancel submission modal
            showCancelSubmissionModal,
            setCancelSubmissionModalVisibility,
            cancelSubmission,
            // confirm form submission modal
            isConfirmSubmissionModalVisible,
            setConfirmSubmissionModalVisibility,
            // discard submission modal
            showDiscardSubmissionModal,
            setDiscardSubmissionModalVisibility,
            handleDiscardSubmission,
            LEGAL_REVIEW_STEP,
            CREATIVE_REVIEW_STEP,
            __submissionLoadingTracker,
            sectionPermissions,
            isAttachmentsLoading,
            isSafetyDocsLoading,
            isSubmissionLoading,
            printReceipts,

            isAnnualSamplesAvailalbe,
            initAnnualSamples,
            isReadOnlyUser,
            cancelThisItemFieldState,
            cancellingSubmission,
            saveBtnFieldState,
            proxyUser
        };
    }
};
</script>

<style scoped>
  .custom-top {
      top: 2.3rem;
  }
</style>
